(function() {
  if (document.readyState !== "loading") {
    init();
  } else {
    document.addEventListener("DOMContentLoaded", init);
  }

  var config;
  var iframe;
  var iframeOrigin;
  var bodyOverflow;

  function init() {
    if (window.stackryWidgetLoaded) {
      console.info("Stackry widget already loaded on page");
    } else {
      window.stackryWidgetLoaded = true;

      readConfig();
      var iframeSrcUrl = new URL(config.iframeSrc, window.location.href);
      iframeOrigin = config.iframeSrc
        ? iframeSrcUrl.protocol === "file:" ? "*" : iframeSrcUrl.origin
        : "https://assets.stackry.com";

      // decorate any widgets already on the page, and observe the page in case widgets get added later
      setupWidgets();
      var mutationObserver = new MutationObserver(setupWidgets);
      mutationObserver.observe(document.body, {
        subtree: true,
        childList: true,
        attributeFilter: ["class"]
      });
    }
  }

  function readConfig() {
    var script = document.getElementById("stackry-widget-script");
    if (!script) {
      console.warn("Cannot read Stackry widget configuration because script tag does not have an ID of 'stackry-widget-script'.");
    } else {
      config = [];
      for (var key in script.dataset) {
        config[key] = script.dataset[key];
      }
      checkBackwardsCompatibleScriptAttributes(config, script);
    }
  }

  // to be removed at some point in the future
  function checkBackwardsCompatibleScriptAttributes(config, script) {
    config.nameId = config.nameId || script.getAttribute("input-name");
    config.firstNameId = config.firstNameId || script.getAttribute("first-name");
    config.lastNameId = config.lastNameId || script.getAttribute("last-name");
    config.addressLine1Id = config.addressLine1Id || script.getAttribute("addressLine1");
    config.addressLine2Id = config.addressLine2Id || script.getAttribute("addressLine2");
    config.cityId = config.cityId || script.getAttribute("city");
    config.stateId = config.stateId || script.getAttribute("state");
    config.countryId = config.countryId || script.getAttribute("country");
    config.countryCodeOptionAttribute = config.countryCodeOptionAttribute || script.getAttribute("country-code-option-attribute");
    config.zipId = config.zipId || script.getAttribute("zip");
    config.referralCode = config.referralCode || script.getAttribute("referral-code");
    config.linkImageUrl = config.linkImageUrl || script.getAttribute("logoUrl");
    config.linkText = config.linkText || script.getAttribute("linkText");
    config.apiKey = config.apiKey || script.getAttribute("api-key");
    config.apiServer = config.apiServer || script.getAttribute("api-server");
    config.warehouseId = config.warehouseId || script.getAttribute("warehouse-id");
    config.fillFormOnclick = config.fillFormOnclick || script.getAttribute("fill-form-onclick");
  }

  function setupWidgets() {
    var widgets = document.getElementsByClassName("stackry-widget");
    for (var i = 0; i < widgets.length; i++) {
      var widget = widgets[i];

      widget.addEventListener("click", openStackryModal);

      // if they have not specified custom content to click to get the widget modal, use configured linkImageUrl, linkText, or default linkText
      if (!widget.innerHTML.trim()) {
        var linkImageUrl = config.linkImageUrl;
        var linkText = config.linkText || "Ship internationally with Stackry";

        if (linkImageUrl) {
          var img = document.createElement("img");
          img.src = linkImageUrl;
          img.alt = "Ship internationally with Stackry";
          widget.appendChild(img);
        } else {
          var a = document.createElement("a");
          a.href = "https://www.stackry.com";
          a.textContent = linkText;
          widget.appendChild(a);
        }
      }
    }
  }

  function openStackryModal(event) {
    event.preventDefault();

    if (document.getElementById("stackry-modal")) {
      console.info("Stackry modal is already open");
      return;
    }

    var backdrop = document.createElement("div");
    backdrop.id = "stackry-modal-backdrop";
    backdrop.style.position = "fixed";
    backdrop.style.top = "0";
    backdrop.style.bottom = "0";
    backdrop.style.left = "0";
    backdrop.style.right = "0";
    backdrop.style.backgroundColor = "#000";
    backdrop.style.opacity = ".5";
    backdrop.style.zIndex = "1000000";

    var intermediateLayer = document.createElement("div");
    intermediateLayer.id = "stackry-modal";
    intermediateLayer.style.position = "fixed";
    intermediateLayer.style.top = "0";
    intermediateLayer.style.bottom = "0";
    intermediateLayer.style.left = "0";
    intermediateLayer.style.right = "0";
    intermediateLayer.style.overflowX = "hidden";
    intermediateLayer.style.overflowY = "auto";
    intermediateLayer.style.opacity = "1";
    intermediateLayer.style.backgroundColor = "transparent";
    intermediateLayer.style.zIndex = "1000001";

    intermediateLayer.addEventListener("click", function() {
      close();
    });

    document.body.append(backdrop);
    document.body.append(intermediateLayer);
    bodyOverflow = document.body.style.overflow;
    document.body.style.overflow = "hidden";

    iframe = document.createElement("iframe");
    iframe.src = config.iframeSrc || "https://assets.stackry.com/stackry-widget/v3/iframe/index.html";
    iframe.name = "stackry-iframe";
    iframe.sandbox.add("allow-scripts");
    iframe.sandbox.add("allow-same-origin");
    iframe.sandbox.add("allow-popups");
    iframe.style.display = "block";
    iframe.style.border = "1px solid #999";
    iframe.style.borderRadius = "6px";
    iframe.style.boxShadow = "0 3px 9px rgba(0, 0, 0, .5)";
    iframe.style.margin = "30px auto";
    iframe.style.width = "94%";
    iframe.style.maxWidth = "540px";
    iframe.style.height = "500px";
    iframe.style.zIndex = "1000001";
    iframe.style.backgroundColor = "#fff";

    addMessageListener();
    intermediateLayer.append(iframe);
  }

  function close() {
    document.getElementById("stackry-modal-backdrop").remove();
    document.getElementById("stackry-modal").remove();
    document.body.style.overflow = bodyOverflow;

    iframe = undefined;
    removeMessageListener();
  }

  function addMessageListener() {
    window.addEventListener("message", messageHandler);
  }

  function removeMessageListener() {
    window.removeEventListener("message", messageHandler);
  }

  function messageHandler(event) {
    if (event.data.action === "stackry-init") {
      initIframe();
    } else if (event.data.action === "stackry-fill-form") {
      fillForm(event.data.data);
    } else if (event.data.action === "stackry-close") {
      close();
    } else if (event.data.action === "stackry-resize") {
      iframe.style.height = event.data.height + "px";
    }
  }

  function initIframe() {
    iframe.contentWindow.postMessage({
      action: "init",
      origin: window.origin,
      config: config,
      hideBodyOverflow: true
    }, iframeOrigin);
  }

  function fillForm(formValues) {
    fillInput(config.nameId, formValues.firstName + " " + formValues.lastName);
    fillInput(config.firstNameId, formValues.firstName);
    fillInput(config.lastNameId, formValues.lastName);
    fillInput(config.addressLine1Id, formValues.addressLine1);
    fillInput(config.addressLine2Id, formValues.addressLine2);
    fillInput(config.cityId, formValues.city);

    var countryElement = config.countryId ? document.getElementById(config.countryId) : undefined;
    if (countryElement) {
      if (config.countryCodeOptionAttribute) {
        for (var i = 0; i < countryElement.options.length; i++) {
          var option = countryElement.options[i];
          if (option.getAttribute(config.countryCodeOptionAttribute) === formValues.country) {
            option.selected = "selected";
          }
        }
      } else {
        countryElement.value = formValues.country;
      }
      // make sure to trigger change event since they might have a select element for state that needs to be populated based on changing this value
      countryElement[0].dispatchEvent(new Event("change", { bubbles: true }));
      // then wait for it to update before trying to set the value
      setTimeout(function() { fillStateAndZip(formValues); }, 1000);
    } else {
      fillStateAndZip(formValues);
    }

    if (config.fillFormOnclick) {
      window[config.fillFormOnclick]();
    }

    close();
  }

  function fillInput(elementId, value) {
    if (elementId) {
      var element = document.getElementById(elementId);
      if (element) {
        element.value = value;
      }
    }
  }

  function fillStateAndZip(formValues) {
    fillInput(config.stateId, formValues.state);
    fillInput(config.zipId, formValues.zip);
  }
}());

